import React from 'react';
import PropTypes from 'prop-types';
// import AddToCalendar from 'react-add-to-calendar';
import styled from 'styled-components';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { convertDateInTimezone } from '../../utils/methods';
const MediumFont = 'Rubik-Medium';

const CalendarButton = styled.div`
  float: left;
  // background-color: ${({ versionStatus, disabled }) => versionStatus ? disabled ? '#9c9c9c' : '#F6B479' : '#159fc9'};
  // border: ${({ versionStatus }) => versionStatus ? 'none' : '1px solid #159fc9'};
  border-radius: ${({ versionStatus }) => versionStatus ? '3px' : '30px'};
  // text-transform:  ${({ versionStatus }) => versionStatus ? 'capitalize' : 'uppercase'};
  // margin-top: 15px;
  width: ${({ versionStatus }) => versionStatus ? '48%' : '100%'};;
  text-align: center;
  // height: 48px;
  font-family:  ${({ versionStatus }) => versionStatus ? `${MediumFont}` : `${MediumFont}`};
  position: relative;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  // opacity: ${({ disabled }) => disabled && '0.5'}; 
  .text-calendar {
    font-size: 18px;
    color: white;
    width: 100%;
    // height: 35px;
    display: flex;
    align-items: center;
    // padding-left: ${({ versionStatus }) => versionStatus ? '0px' : '29px'};
    justify-content: ${({ versionStatus }) => versionStatus ? 'center' : ''};
    // float: left;
    margin: 0px;
    display: flex;
    justify-content: center;
  }
  .react-add-to-calendar {
    width: 100%;
    height: 100%;
    .react-add-to-calendar__wrapper {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor:${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
      .react-add-to-calendar__button {
        padding: 0;
        font-size: 18px;
        color: white;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        // line-height: 33px;
        font-family: ${MediumFont};

        > span {
          // padding-left: ${({ versionStatus }) => versionStatus ? '0px' : '29px'};
          display: flex;
    justify-content: center;
    // padding-top: 8px;
          .react-add-to-calendar__icon--right {
            display: none;
          }
        }

        &:focus, &:active, &:hover {
          outline: none;
          text-decoration: none;
        }
      }
    }
 
    .react-add-to-calendar__dropdown {
      width: ${({ widthNew }) => widthNew ? widthNew : '100%'};
      z-index: 1;
      margin-top: 5px;
      border: none;
      margin-left: ${({ marginNew }) => marginNew ? marginNew : ''};
      > ul {
        padding: 0 0 0 5px;
      >li{
        padding: 5px 0 5px 0;
        >a{
          color:#3a3a3a;
          >i{
            color:#3a3a3a;
            margin-right: 5px;
          }
        }
      }
      }
    }
  }

  > svg {
    float: left;
    width: 16px;
    height: 15px;
    position: absolute;
    top: 6px;
    left: 8px;
  }
`;

const AddInCalendar = ({ event, versionStatus, width, margin, t }) => {
  window.console.log("")
  // const AddInCalendarData = {
  //   title: event.title,
  //   description: event.body,
  //   location: event.event_location ? event.event_location : event.event_encrypted_link,
  //   startTime: convertDateInTimezone(event.start_date)._d,
  //   endTime: convertDateInTimezone(event.end_date)._d
  // };
  return (
    <CalendarButton disabled={convertDateInTimezone(event.end_date)._d <= moment()._d} versionStatus={versionStatus} widthNew={width} marginNew={margin}>
      {convertDateInTimezone(event.end_date)._d >= moment()._d
        ?
        <button>Add to calendar</button>
        :
        <div className="text-calendar">
          {t("Add to calendar")}
        </div>
      }
    </CalendarButton>
  )
};

AddInCalendar.propTypes = {
  event: PropTypes.object.isRequired,
  versionStatus: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.number,
  t: PropTypes.func
};

export default (withTranslation()(AddInCalendar));
