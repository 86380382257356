import styled from 'styled-components';
import {Modal} from "react-bootstrap";
import { UserButton } from '../EngagementSection/styles';
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';

const StyledModal = styled(Modal)`
  .modal-dialog { 
    .modal-header {
      border-bottom: 0px;
    }
    .modal-content{
      float: ${({width}) => width && 'left'};
      width:700px;
      margin-left:-100px;
    }
    @media (min-width: 700px){
      width:${({width}) => width};
    }
  }
  .reward-modal-header {
    text-align: right;
    color: #999;
    padding: 5px 15px;
    .close-icon {
      cursor: pointer;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 25px;
  .close {
    float: right;
    margin-top: -20px;
    margin-right: -16px;
  }
  .pdfHeader {
    text-align: center;
    font-family: ${RegularFont};
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
  }
  .subHeader {
    text-align: center;
    font-family: ${MediumFont};
    width: 100%;
    font-size: 14px;
  }
`;


const StyledBody = styled(Modal.Body)`
  padding: 0px;
  width: ${({ demo }) => demo && '100%'};
  float: ${({ demo }) => demo && 'left'};
  background-color: ${({ demo }) => demo && 'white'};
  padding-bottom: ${({ demo }) => demo && '20px'};
`;

const Padding = styled.div`
  padding: 10px 25px;
  display: flex;
  align-items: center;
  .name {
    margin-left: 10px;
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(153,153,153);
    font-family: ${RegularFont};
    text-transform: capitalize;
  }
`;

const Button = styled(UserButton)`
  padding: ${({padding}) => padding ? padding : '7px 10px'};
  margin-bottom: 40px !important;
  opacity: ${({disabled}) => disabled && '0.5'};
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
`;

const Container = styled.div`
  width: 70%;
  margin-left: 15%;
  position: relative;
  .headerName{
    font-size: 18px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    font-family: ${RegularFont};
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
  }
  .subTitle{
    font-size: 11px;
    letter-spacing: 0px;
    color: rgb(187,187,187);
    font-family: ${RegularFont};
    text-align: center;
    text-transform: uppercase;
    margin-top: 4px;
  }
  .headerChallenge{
    font-size: 18px;
    letter-spacing: 0px;
    color: #0D4270;
    font-family: "Rubik-Medium";
    text-align: center;
    text-transform: capitalize;
    line-height: 24px;
  }
  @media (max-width: 720px) {
    width: 100%;
    margin: 0px;
  }
`;

const StyledModalNew = styled(Modal)`
  width: 700px;
  height: 495px;
.modal-lg{
  width: 700px;
  height: 495px;
}
  .modal-dialog { 
    .modal-header {
      border-bottom: 0px;
    }
    .modal-content{
      float: ${({width}) => width && 'left'};
      width:700px;
      margin-left: -100px;
    }
    @media (min-width: 700px){
      width:${({width}) => width};
    }
  }
  .reward-modal-header {
    text-align: right;
    color: #999;
    padding: 5px 15px;
    .close-icon {
      cursor: pointer;
    }
  }
`;

const CrossButton = styled.div`
width: 100%;
height: 0px;
display: flex;
justify-content: flex-end;
>img{
  width: 24px;
  height: 24px;
  position: relative;
  top: 20px;
  right: 25px;
  cursor: pointer;
}
`;

const StyledModalV2 = styled(Modal)`
  .modal-dialog { 
    .modal-header {
      border-bottom: 0px;
    }
    .modal-content{
      float: ${({width}) => width && 'left'};
      width: 700px;
      margin-left:-100px;
    }
    @media (min-width: 700px){
      width:${({width}) => width};
    }
  }
  .reward-modal-header {
    text-align: right;
    color: #999;
    padding: 5px 15px;
    .close-icon {
      cursor: pointer;
    }
  }
`;

export { Container, StyledModal, StyledHeader, StyledBody, Padding, Button, StyledModalNew, CrossButton, StyledModalV2 };
