/* eslint-disable no-console,no-undef, camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {  CheckMark, SubmitContainer, } from '../AddWellnessInitiative/styles';
import { CommonContainer } from '../EngagementSection/styles';
import { StyledModal, StyledBody, StyledHeader, Padding, Button, Container } from './styles';

class AddInputGoals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      check: ''
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { wellnessGoals } = this.props;
    const inputGoalsData = [];
    for(let i=0; i<wellnessGoals?.top_goals?.length; i++) {
      let obj = {};
      obj.name = wellnessGoals && wellnessGoals?.top_goals&& wellnessGoals?.top_goals[i]?.name;
      obj.checked = true;
      obj.goal_type = wellnessGoals?.top_goals[i]?.goal_type;
      obj.id = wellnessGoals?.top_goals[i]?.id;
      inputGoalsData.push(obj)
    }

    for(let i=0; i<wellnessGoals?.other_goals?.length; i++){
      let  obj = {};
      obj.name = wellnessGoals &&wellnessGoals?.other_goals &&wellnessGoals?.other_goals[i]?.name;
      obj.checked = false;
      obj.goal_type = wellnessGoals?.other_goals[i]?.goal_type;
      obj.id = wellnessGoals?.other_goals[i]?.id;
      inputGoalsData.push(obj)
    }
    this.setState({
      data: inputGoalsData,
      check: wellnessGoals?.top_goals?.length
    })
  }

  updateGoal = () => {
    const { updateWellnessGoal, userId, onClose } = this.props;
    const { data } = this.state;
    let obj = {
      'top_goals': [],
      'other_goals': []
    };
    let updatedetail = {
      uid: userId,
      "goal_id": []
    }
    for(let i=0; i< data.length; i++) {
      if(data[i].checked) {
        obj.top_goals.push(data[i]);
        updatedetail.goal_id.push(data[i].id)
      }
      else {
        obj.other_goals.push(data[i]);
      }
    } 
    if(updatedetail.goal_id.length > 1) {
      updateWellnessGoal(obj, updatedetail);
      onClose();
    } 
    else {
      toast.error("Please select two goals");
    }
  }

  changeCheckData = (index) => {
    let changeData = this.state.data;
    const { check } = this.state;
    if(changeData[index].checked){
      changeData[index].checked = !changeData[index].checked;
      this.setState({
        data: changeData,
        check: check - 1
      })
    }
    else {
      if(check < 2) {
        changeData[index].checked = !changeData[index].checked;
        this.setState({
          data: changeData,
          check: check + 1
        })
      }
    }
  }

  showCheckboxData = (checkBoxData) => (
    checkBoxData.map((data, index) => (
      <CommonContainer key={index} borderTop={1}>
        <Padding>
          <div className="check">
            <CheckMark checked={data.checked} onClick={() => this.changeCheckData(index)}/>
          </div>
          <div className="name">{data.name}</div>
        </Padding>
      </CommonContainer>
    ))
  )

  render() {
    const { showModal } = this.props;
    const { data, check } = this.state;
    return(
      <StyledModal show={showModal} onHide={this.props.onClose}>
        <StyledHeader closeButton>
          <Container>
            <div className="headerName">
              Top Two Goals
            </div>
            <div className="subTitle">
              Choose Top two Goals
            </div>
          </Container>
        </StyledHeader>
        <StyledBody >
          {this.showCheckboxData(data)}
          <CommonContainer borderTop>
            <SubmitContainer>
              <Button 
                fontSize="14px" 
                radius="22px" 
                bgColor="#159fc9" 
                width="175px" 
                height='45px' 
                Align={1}
                marginTop="20px"
                onClick={this.updateGoal}
                disabled={check < 2}
              >
                Submit
              </Button>
            </SubmitContainer>
          </CommonContainer>
        </StyledBody>
      </StyledModal>
    );
  }
}

AddInputGoals.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  wellnessGoals: PropTypes.object.isRequired,
  updateWellnessGoal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired
}

export default AddInputGoals;