import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { convertDateInTimezone } from "../../utils/methods";
import { Modal } from "react-bootstrap";
import { StyledModal } from "../LogWorkoutCategoryV2/styles";
import DOMPurify from 'dompurify';
import parse from 'react-html-parser';

const MediumFont = "Rubik-Medium";

const CalendarButton = styled.div`
  // background-color: ${({ versionStatus, disabled }) =>
    versionStatus ? (disabled ? "#9c9c9c" : "#F6B479") : "#159fc9"};
  // border: ${({ versionStatus }) =>
    versionStatus ? "none" : "1px solid #159fc9"};
  border-radius: ${({ versionStatus }) => (versionStatus ? "3px" : "30px")};
  // text-transform:  ${({ versionStatus }) =>
    versionStatus ? "capitalize" : "uppercase"};
  // margin-top: 15px;
  width: ${({ versionStatus }) => (versionStatus ? "48%" : "100%")};
  text-align: center;
  // height: 48px;
  font-family: ${({ versionStatus }) =>
    versionStatus ? `${MediumFont}` : `${MediumFont}`};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  // opacity: ${({ disabled }) => disabled && "0.5"};
  .react-add-to-calendar__dropdown {
    position: initial;
    margin: 0px;
    width: 100%;
    padding: 0px;
    box-shadow: none;
    border: none;
  }
  .react-add-to-calendar__dropdown ul li {
    padding: 17px 0 17px 0;
    border-bottom: 1px solid #ededed;
    padding-left: 30%;
    list-style-type: none;
    text-align: left;
  }
  .react-add-to-calendar__dropdown ul li a {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
  }
  .react-add-to-calendar__dropdown ul li a i {
    height: 24px;
    width: 24px;
  }
  .react-add-to-calendar__dropdown ul li span {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0D4270;
  }
  .react-add-to-calendar__dropdown ul li span i {
    height: 24px;
    width: 24px;
  }
  .text-calendar {
    font-size: 18px;
    color: white;
    width: 100%;
    // height: 35px;
    display: flex;
    align-items: center;
    // padding-left: ${({ versionStatus }) => (versionStatus ? "0px" : "29px")};
    justify-content: ${({ versionStatus }) => (versionStatus ? "center" : "")};
    // float: left;
    margin: 0px;
    display: flex;
    justify-content: center;
  }
  .react-add-to-calendar {
    width: 100%;
    height: 100%;
    .react-add-to-calendar__wrapper {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      .react-add-to-calendar__button {
        padding: 0;
        font-size: 18px;
        color: white;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        // line-height: 33px;
        font-family: ${MediumFont};

        > span {
          // padding-left: ${({ versionStatus }) =>versionStatus ? "0px" : "29px"};
          display: flex;
          justify-content: center;
          // padding-top: 8px;
          .react-add-to-calendar__icon--right {
            // display: none;
          }
        }

        &:focus,
        &:active,
        &:hover {
          outline: none;
          text-decoration: none;
        }
      }
    }

    > li {
      padding: 17px 0 17px 0;
      border: 1px solid #ededed;
    }

    .react-add-to-calendar__dropdown {
      width: ${({ widthNew }) => (widthNew ? widthNew : "100%")};
      z-index: 1;
      margin-top: 5px;
      border: none;
      margin-left: ${({ marginNew }) => (marginNew ? marginNew : "")};
      position: "";
      > ul {
        padding: 0 0 0 5px;
        padding-left: 30%;
        > li {
          padding: 17px 0 17px 0;
          border: 1px solid #ededed;
          > a {
            color: #3a3a3a;
            > i {
              color: #3a3a3a;
              margin-right: 5px;
            }
          }
           span {
            color: #3a3a3a;
            > i {
              color: #3a3a3a;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  > svg {
    float: left;
    width: 16px;
    height: 15px;
    position: absolute;
    top: 6px;
    left: 8px;
  }
`;

class AddInCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      check: "",
      showModal: true,
    };
  }

  convertHtmlToText(html) {
    
    const sanitizedHtml = DOMPurify.sanitize(html);
    
    const docFragment = document.createRange().createContextualFragment(sanitizedHtml);
    
    const container = document.createElement('div');
    container.appendChild(docFragment);

    return container.textContent || container.innerText || '';
    
    // const element = document.createElement('div');
    // element.innerHTML = DOMPurify.sanitize(html) // html;
    // return element.textContent || element.innerText || '';
  }

  formatDateAndTime = (dateTime, yahoo, outlook) => {
 
    const targetDate = moment(
      convertDateInTimezone(dateTime)._d
    );
    const inputDate = new Date(targetDate);
    const yahooFormattedDate = `${inputDate.getFullYear()}${String(inputDate.getMonth() + 1).padStart(2, '0')}${String(inputDate.getDate()).padStart(2, '0')}T${String(inputDate.getHours()).padStart(2, '0')}${String(inputDate.getMinutes()).padStart(2, '0')}${String(inputDate.getSeconds()).padStart(2, '0')}`;
 
    const outlookFormattedDate = `${inputDate.getFullYear()}-${String(inputDate.getMonth() + 1).padStart(2, '0')}-${String(inputDate.getDate()).padStart(2, '0')}T${String(inputDate.getHours()).padStart(2, '0')}:${String(inputDate.getMinutes()).padStart(2, '0')}`;
 
    return (
      yahoo ? yahooFormattedDate : outlook ? outlookFormattedDate : targetDate.toISOString().replace(/[-:]/g, "").replace(/\.\d{3}Z/, "") + "Z"
    );
  };


  downloadIcsFile = ()=>{
    const{event, hideCalendar}= this.props;
    const location = event.event_location ? event.event_location : event.event_encrypted_link;
    const desc = parse(event.body)
    const icsContent = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      "DTSTART:" + this.formatDateAndTime(event.start_date),
      "DTEND:" + this.formatDateAndTime(event.end_date),
      "SUMMARY:" + event.title,
      "DESCRIPTION:" + desc?.split('\n')?.join('\\n'),
      "LOCATION:" + location,      
      "BEGIN:VALARM",
      "TRIGGER:-PT15M",
      "REPEAT:1",
      "DURATION:PT15M",
      "ACTION:DISPLAY",
      "DESCRIPTION:Reminder",
      "END:VALARM",
      "END:VEVENT",
      "END:VCALENDAR"
    ].join("\n");
    const blob = new window.Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const blobUrl = window.URL.createObjectURL(blob);

    if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
    // Open/Save link in IE and Edge
      window.navigator.msSaveBlob(blob, 'download.ics');
    } else {
    // Open/Save link in Modern Browsers
      window.open(blobUrl);
    }

    hideCalendar(event.id)
  }

  render() {
    const {
      event,
      versionStatus,
      width,
      margin,
      show,
      closeModal,
      hideCalendar,
    } = this.props;

    const googleCalendarBaseUrl = "https://calendar.google.com/calendar/render";
    const yahooCalendarBaseUrl = "https://calendar.yahoo.com/?v=60&";
    // const outlookCalendarBaseUrl = "https://outlook.live.com/owa/?path=/calendar/view/month&rru=addevent&";

    const startFormatted = this.formatDateAndTime(event.start_date);
    const endFormatted = this.formatDateAndTime(event.end_date);
    
    const queryParams = new window.URLSearchParams({
      action: "TEMPLATE",
      text: event.title,
      details: event.body,
      dates:`${startFormatted}/${endFormatted}`,
      location: event.event_location ? event.event_location : event.event_encrypted_link,
      ctz: event.abbreviation // Use the correct property name
    });

    const yahooQueryParams = new window.URLSearchParams({
      action: "TEMPLATE",
      title: event.title,
      desc: event.body,
      st:this.formatDateAndTime(event.start_date, true),
      et:this.formatDateAndTime(event.end_date, true),
      in_loc: event.event_location ? event.event_location : event.event_encrypted_link,
      ctz: event.abbreviation // Use the correct property name
    });

    // const outlookQueryParams = new window.URLSearchParams({
    //   // action: "TEMPLATE",
    //   subject: event.title,
    //   body: event.body,
    //   startdt:this.formatDateAndTime(event.start_date, false,true),
    //   enddt:this.formatDateAndTime(event.end_date, false,true),
    //   location: event.event_location ? event.event_location : event.event_encrypted_link,
    //   ctz: event.abbreviation // Use the correct property name
    // });
    
    const googleCalendarEncodedLink = `${googleCalendarBaseUrl}?${queryParams.toString()}`;
    const yahooCalendarEncodedLink = `${yahooCalendarBaseUrl}?${yahooQueryParams.toString()}`;
    // const outlookCalendarEncodedLink = `${outlookCalendarBaseUrl}?${outlookQueryParams.toString()}`;
    
    return (
      <StyledModal padding="0" show={show}>
        <div className={"modal-container"}>
          <div className={"left-modal"}>
            <div className={"text"}>{"Add to Calendar"}</div>
          </div>
          <div>
            <img
              src="/public/images/NewDashboardV2/new_close_icon.png"
              width="22px"
              height="22px"
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => closeModal(event.id)}
            />
          </div>
        </div>
        <Modal.Body>
          <CalendarButton
            disabled={convertDateInTimezone(event.end_date)._d <= moment()._d}
            versionStatus={versionStatus}
            widthNew={width}
            marginNew={margin}
          >
            <div className="react-add-to-calendar__dropdown">
              <ul>
                <li onClick={() => this.downloadIcsFile() }>
                  <span className="google-link"><img src="/public/images/Events/apple.png" width={"20px"} height={"24px"} style={{marginRight:"15px"}}/>Apple Calendar</span>
                </li>
                <li onClick={() => hideCalendar(event&& event.id)}>
                  <a
                    className="google-link"
                    href={googleCalendarEncodedLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/public/images/Events/google.png" width={"24px"} height={"24px"} style={{marginRight:"15px"}}/>Google Calendar
                  </a>
                </li>
                <li onClick={() => this.downloadIcsFile() }>
                  <span className="google-link"><img src="/public/images/Events/outlook.png" width={"24px"} height={"24px"} style={{marginRight:"15px"}}/>Outlook Calendar</span>
                </li>
                {/* <li onClick={() => hideCalendar(event.id)}>
                  <a                     
                    className="google-link"
                    target="_blank"
                    rel="noopener noreferrer" 
                    href={outlookCalendarEncodedLink}><i className="fa fa-windows"></i>Outlook Calendar</a>
                </li> */}
                <li onClick={() => hideCalendar(event &&event.id)}>
                  <a
                    className="yahoo-link"
                    href={yahooCalendarEncodedLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/public/images/Events/yahoo.png" width={"24px"} height={"24px"} style={{marginRight:"15px"}}/>Yahoo Calendar
                  </a>
                </li>
              </ul>
            </div>
          </CalendarButton>
        </Modal.Body>
      </StyledModal>
    );
  }
}

AddInCalendar.propTypes = {
  event: PropTypes.object.isRequired,
  versionStatus: PropTypes.number,
  width: PropTypes.number,
  margin: PropTypes.number,
  t: PropTypes.func,
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  hideCalendar: PropTypes.func,
};

export default withTranslation()(AddInCalendar);
